<script setup>
import { toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useLenis } from '@/components/scroll/Lenis.js';
import Arrow from '@/icons/arrow.svg';
import Feuilles from '@/icons/feuilles.svg';

const route = useRoute();
const lenis = useLenis();

const props = defineProps({
    link: {
        type: Object,
        default: () => {},
    },
});

const { link } = toRefs(props);

const checkIfSame = (event) => {
    if (route.path === event.currentTarget.pathname) {
        lenis.value.scrollTo(event.currentTarget.hash, {
            duration: 1.5,
        });
        event.preventDefault();
    }
};

</script>
<template>
    <div class="NavigationSubmenu">
        <div class="flex flex-col gap-6 xl:flex-row xl:items-start">
            <!-- xl:grid-cols-1 xl:grid-cols-2 xl:grid-cols-3 -->
            <div class="grid grid-cols-1 gap-2 xl:py-3" :class="['xl:grid-cols-' + (Math.ceil(link.children.length / 3) ?? 1)]">
                <!-- xl:col-start-1 xl:col-start-2 -->
                <!-- xl:row-start-1 xl:row-start-2 xl:row-start-3 -->
                <router-link
                    v-for="(child, childIndex) in link.children"
                    :key="child.title"
                    :to="setUri({ uri: child.uri })"
                    class="group flex gap-6 rounded-xl p-2 text-left transition duration-500 hover:bg-bright-gray xl:w-100"
                    :class="['xl:col-start-' + Math.ceil(((childIndex + 1) / 3) % 3), 'xl:row-start-' + ((childIndex % 3) + 1)]"
                    v-on:click="checkIfSame($event)"
                >
                    <div
                        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-xl fill-current p-2 text-white"
                        :class="[(link.color === 'red' || link.color === 'indigo' ? 'bg-light-' : 'bg-dark-') + link.color]"
                        v-html="child.icon.inline"
                    />
                    <div class="flex w-full">
                        <div class="mr-4">
                            <div v-if="child.suptitle" class="text-xs font-normal leading-tight text-dark-gray">
                                {{ child.subtitle }}
                            </div>
                            <div class="text-lg font-bold text-black">
                                {{ child.title }}
                            </div>
                            <div v-if="child.subtitle" class="text-xs font-normal leading-tight text-dark-gray xl:max-w-xs">
                                {{ child.subtitle }}
                            </div>
                        </div>
                        <Arrow
                            class="ml-auto h-auto w-4 shrink-0 fill-current opacity-0 transition duration-500 group-hover/sub:opacity-100"
                            :class="['text-light-' + link.color]"
                        />
                    </div>
                </router-link>
            </div>
            <router-link :to="setUri({ uri: link.uri })" class="-mb-12 flex shrink-0 grow-0 items-end md:-mb-20 xl:m-0 xl:mx-auto xl:self-end">
                <Feuilles class="-ml-3 mr-auto w-4/12 shrink-0 fill-current xl:hidden" :class="['text-light-' + link.color]" />
                <svg
                    class="h-auto w-4/12 shrink-0 xl:w-54"
                    width="280"
                    height="302"
                    viewBox="0 0 280 302"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.82825 302.061L38.2534 302.061C38.2534 292.548 30.5475 284.842 21.034 284.842C11.5342 284.855 3.82825 292.561 3.82825 302.061Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M14.7151 286.051C14.7151 286.051 22.3123 295.945 23.9568 302.061"
                        stroke="white"
                        stroke-width="0.625701"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M28.3457 286.471C28.3457 286.471 26.6876 292.383 20.5039 294.652"
                        stroke="white"
                        stroke-width="0.625701"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M22.7336 298.826C22.7336 298.826 12.7173 298.555 6.01712 293.322"
                        stroke="white"
                        stroke-width="0.625701"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M74.745 310.364C74.745 310.364 85.3049 258.149 39.3277 239.258C39.3277 239.258 24.5275 284.61 74.745 310.364Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M39.3279 239.258C39.3279 239.258 69.241 280.601 74.1064 311.112"
                        stroke="white"
                        stroke-width="0.605328"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M67.4741 309.006C67.4741 309.006 59.1159 256.397 9.44199 254.766C9.44199 254.779 11.4262 302.428 67.4741 309.006Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M67.4741 309.006C67.4741 309.006 31.0783 264.863 9.44199 254.766"
                        stroke="white"
                        stroke-width="0.605328"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M33.7014 272.951L33.2937 259.795"
                        stroke="white"
                        stroke-width="0.632346"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M47.8765 287.029L46.8979 269.361"
                        stroke="white"
                        stroke-width="0.628228"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M29.1484 268.818L12.1737 269.308"
                        stroke="white"
                        stroke-width="0.629452"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M42.8887 281.893L18.7924 282.477"
                        stroke="white"
                        stroke-width="0.632224"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M56.5474 296.422L36.4332 299.235"
                        stroke="white"
                        stroke-width="0.625701"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M77.5989 321.807C77.5989 321.807 61.9561 283.618 97.7539 256.246C97.7539 256.246 116.767 292.791 77.5989 321.807Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M87.0576 315.881C87.0576 315.881 95.6197 261.994 146.503 260.336C146.503 260.336 144.478 309.154 87.0576 315.881Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M87.0576 315.881C87.0576 315.881 124.337 270.678 146.503 260.336"
                        stroke="white"
                        stroke-width="0.620006"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M126.634 274.538L127.069 263.979"
                        stroke="white"
                        stroke-width="0.647677"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M110.148 290.154L111.535 272.812"
                        stroke="white"
                        stroke-width="0.643464"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M121.633 278.916L141.937 279.908"
                        stroke="white"
                        stroke-width="0.644714"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M104.902 295.943L128.944 297.588"
                        stroke="white"
                        stroke-width="0.647554"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M150.187 282.599C147.292 282.599 144.588 283.414 142.291 284.841C138.349 279.446 131.975 275.953 124.8 275.953C115.843 275.953 108.165 281.389 104.862 289.136C102.837 287.315 100.173 286.214 97.2377 286.214C90.9316 286.214 85.8215 291.324 85.8215 297.63L103.136 297.63L108.654 297.63L135.169 297.63L146.463 297.63L165.205 297.63C165.205 289.326 158.491 282.599 150.187 282.599Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M120.831 298.038C120.831 298.038 124.215 280.289 126.498 275.953"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M121.674 293.839L139.423 281.635"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M112.378 279.432L123.019 287.681"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M145.498 298.038L158.926 285.398"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M148.121 282.312L151.627 292.274"
                        stroke="white"
                        stroke-width="0.87793"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M99.5344 297.615L92.4673 286.783"
                        stroke="white"
                        stroke-width="0.87793"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M189.457 331.122C189.457 331.122 200.017 278.907 154.04 260.016C154.04 260.016 139.24 305.368 189.457 331.122Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M154.04 260.016C154.04 260.016 183.953 301.358 188.819 331.869"
                        stroke="white"
                        stroke-width="0.605328"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M182.187 329.764C182.187 329.764 173.828 277.154 124.154 275.523C124.154 275.537 126.139 323.186 182.187 329.764Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M182.187 329.764C182.187 329.764 145.791 285.621 124.154 275.523"
                        stroke="white"
                        stroke-width="0.605328"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M148.414 293.708L148.006 280.553"
                        stroke="white"
                        stroke-width="0.632346"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M162.589 307.787L161.61 290.119"
                        stroke="white"
                        stroke-width="0.628228"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M143.861 289.576L126.886 290.065"
                        stroke="white"
                        stroke-width="0.629452"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M192.311 342.565C192.311 342.565 176.668 304.375 212.466 277.004C212.466 277.004 231.479 313.549 192.311 342.565Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M201.77 336.639C201.77 336.639 210.332 282.752 261.216 281.094C261.216 281.094 259.191 329.911 201.77 336.639Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M201.77 336.639C201.77 336.639 239.049 291.436 261.216 281.094"
                        stroke="white"
                        stroke-width="0.620006"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M241.346 295.296L241.781 284.736"
                        stroke="white"
                        stroke-width="0.647677"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M224.861 310.912L226.247 293.57"
                        stroke="white"
                        stroke-width="0.643464"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M236.345 299.674L256.649 300.666"
                        stroke="white"
                        stroke-width="0.644714"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M264.899 303.357C262.004 303.357 259.3 304.172 257.003 305.599C253.062 300.204 246.688 296.711 239.512 296.711C230.555 296.711 222.877 302.147 219.574 309.894C217.549 308.073 214.885 306.972 211.95 306.972C205.644 306.972 200.534 312.082 200.534 318.388L217.848 318.388L223.366 318.388L249.881 318.388L261.175 318.388L279.917 318.388C279.917 310.084 273.203 303.357 264.899 303.357Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M235.543 318.796C235.543 318.796 238.927 301.046 241.21 296.711"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M227.09 300.189L237.731 308.439"
                        stroke="white"
                        stroke-width="0.878338"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M106.676 400.993L135.14 400.993L135.14 12.0613C135.14 8.68336 132.408 5.95117 129.03 5.95117L112.786 5.95117C109.408 5.95117 106.676 8.68336 106.676 12.0613L106.676 400.993Z"
                        class="fill-current"
                        :class="['text-light-' + link.color]"
                    />
                    <path
                        d="M0.551346 108.428V31.6341C0.551346 26.829 3.39018 22.9434 6.90135 22.9434H202.218C203.727 22.9434 205.191 23.6893 206.342 25.025L239.123 63.4306C242.081 66.8999 242.081 73.1621 239.123 76.6487L206.342 115.037C205.191 116.39 203.727 117.119 202.218 117.119H6.90135C3.39018 117.119 0.551346 113.233 0.551346 108.428Z"
                        class="fill-current"
                        :class="['text-dark-' + link.color]"
                    />
                    <rect
                        x="44.2515"
                        y="109.463"
                        width="149.17"
                        height="44.7511"
                        rx="5.96681"
                        transform="rotate(-5 44.2515 109.463)"
                        class="fill-current"
                        :class="['text-bright-' + link.color]"
                    />
                    <text
                        x="50%"
                        y="141"
                        font-weight="bold"
                        font-size="24px"
                        text-anchor="middle"
                        transform="rotate(-5 44.2515 109.463) translate(-21 0)"
                        class="fill-current"
                        :class="['text-dark-' + link.color]"
                    >
                        {{ link.subsubtitle }}
                    </text>
                    <text
                        x="50%"
                        text-anchor="middle"
                        y="61"
                        font-weight="bold"
                        font-size="24px"
                        transform="translate(-22 0)"
                        class="fill-current text-white"
                    >
                        {{ link.suptitle }}
                    </text>
                    <text
                        x="50%"
                        text-anchor="middle"
                        y="86"
                        font-weight="bold"
                        font-size="31px"
                        transform="translate(-22 0)"
                        class="fill-current text-white"
                    >
                        {{ link.title }}
                    </text>
                </svg>
                <Feuilles class="-mr-3 ml-auto w-4/12 shrink-0 -scale-x-100 fill-current  xl:hidden " :class="['text-light-' + link.color]" />
            </router-link>
        </div>
        <div
            :class="['bg-light-' + link.color, link.color === 'red' || link.color === 'indigo' ? 'text-white' : 'text-dark-' + link.color]"
            class="relative -mx-3 flex flex-col items-center p-3 md:flex-row md:items-center md:justify-between"
        >
            <div class="mb-2 px-3 text-left text-base font-medium leading-snug md:max-w-xs lg:text-xs lg:leading-snug xl:mb-0">
                {{ link.featuredContentTitle }}
            </div>
            <div class="w-full md:w-auto">
                <router-link
                    :class="['bg-dark-' + link.color, 'hover:bg-bright-' + link.color, 'hover:text-dark-' + link.color]"
                    class="group/featured flex w-full items-center whitespace-nowrap rounded-xl px-6 py-3 font-bold text-white transition duration-500 md:w-auto"
                    :to="setUri({ uri: link.uri })"
                >
                    <span class="w-full whitespace-nowrap md:w-auto">
                        {{ link.featuredContentLinkText }}
                    </span>
                    <Arrow
                        class="ml-5 h-auto w-4 shrink-0 fill-current transition-transform duration-500 group-hover/featured:translate-x-1"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>
