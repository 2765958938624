export default {
    author: 'Guimauve x Turbulences',
    name: 'QuebecBio',
    cms: 'craft',
    locale: [
    // first is default
        { lang: 'fr', craftID: 1, prefix: false },
    // { lang: 'en', craftID: 3, prefix: true }
    ],
};
