<script setup>
import { ref } from 'vue';
import { GA } from '@/mixins/Ga';

const fixedTagColor = ref('dark-blue');
const ga = new GA({ category: 'midBody', action: 'clicInteract' });

const props = defineProps({
    post: {
        type: Object,
        default: () => {},
    },
    tag: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'blue',
    },
    tagColor: {
        type: String,
        default: '',
    },
});

if (props.tagColor === '') {
    switch (props.post.typeHandle) {
    case 'product':
        fixedTagColor.value = 'dark-green';
        break;
    case 'recette':
        fixedTagColor.value = 'light-red';
        break;
    case 'blog':
        fixedTagColor.value = 'dark-yellow';
        break;
    default:
        fixedTagColor.value = 'dark-blue';
        break;
    }
}
</script>
<template>
    <component
        :is="post.uri ? 'router-link': 'div'"
        :to="post.uri ? setUri({ uri: post.uri }) : null"
        class="Card__block"
        v-on:click="ga.send({ label: post.title })"
    >
        <div class="Card__cover relative -mx-2 overflow-hidden rounded-xl">
            <Cover
                v-if="post.cover && post.cover[0].url != ''"
                class="relative z-1 h-full w-full"
                :imgclass="post.uri ? 'can-hover:hover:scale-110': ''"
                :clip-path="false"
                :color="color"
                :data="post.cover"
            />
            <div v-if="tag && post.typeHandle" class="Card__cover absolute bottom-0 z-10 h-12 w-full bg-gradient-to-b from-black/0 to-black/50">
                <span
                    :class="['bg-'+fixedTagColor]"
                    class="absolute bottom-2 right-2 z-10 whitespace-nowrap rounded-full px-3 pb-1.5 pt-2 text-xs leading-none text-white"
                    href="#"
                >
                    {{ typeHandleToName(post.typeHandle) }}
                </span>
            </div>
        </div>
        <div class="py-2">
            <div v-if="post.typeHandle === 'product' || (post.category && post.category[0])" class="Card__category mb-1 flex flex-wrap gap-x-2 text-xs font-bold leading-snug">
                <span v-if="post.day && post.month && post.year" class="Blogs__date lowercase">
                    {{ post.day }} {{ FRMonth[post.month - 1] }} {{ post.year }} •
                </span>
                <span v-if="post.typeHandle === 'product'">
                    Produits Bio
                </span>
                <span v-else>
                    {{ post.category[0].title }}
                </span>
            </div>
            <div class="text-lg font-bold leading-tight">
                {{ post.title }}
            </div>
        </div>
    </component>
</template>
