<script setup>
// eslint-disable-next-line no-unused-vars
import {
    computed, ref, watch, onUnmounted, nextTick, onMounted,
} from 'vue';
import { useQuery } from 'villus';
import { gsap } from 'gsap';
import { useRoute } from 'vue-router';

import { GA } from '@/mixins/Ga';
import { useStore } from '@/store/Store.js';
import { useLenis } from '@/components/scroll/Lenis.js';
import Caret from '@/icons/caret.svg';
import LogoLetter from '@/components/logo-letter/LogoLetter.vue';
import Socials from '@/components/socials/Socials.vue';
import MagnifyingGlass from '@/icons/magnifying-glass.svg';
import NavigationLightbox from './NavigationLightbox.vue';
import NavigationSubmenu from './NavigationSubmenu.vue';
import Loader from '@/components/loader/Loader.vue';
import WaveTopSoft from '@/icons/wave-top-soft.svg';
import WaveTopSoftSmall from '@/icons/wave-top-soft-small.svg';

const store = useStore();
const logo = ref(null);
const burger = ref(null);
const location = ref(null);
const top = ref(null);
const rootEl = ref(null);
const topLevelLinks = ref([]);

const route = useRoute();
const lenis = useLenis();
const variables = computed(() => ({ lang: store.lang.value }));
const navOpened = ref(false);
const garbage = [];

// Add meta tag for color
let meta = document.head.querySelector('meta[name="theme-color"]');
// const headerbarColors = {
//     dark: {
//         red: '#BB3C3A',
//         blue: '#33567A',
//         green: '#679840',
//         yellow: '#C48D2E',
//         white: '#000000',
//     },
//     light: {
//         red: '#F1716F',
//         blue: '#98CCE1',
//         green: '#BCD75A',
//         yellow: '#F9DD6B',
//         white: '#ffffff',
//     },
// };

const ga = new GA({ category: 'headerMenu', action: 'clicInterne' });

const toggleSearch = () => {
    store.searchOpened.value = !store.searchOpened.value;

    ga.send({
        category: 'search',
        action: 'clicInteract',
        label: store.searchOpened.value ? 'openSearch' : 'closeSearch',
    });
};

watch(store.searchOpened, () => {
    if (store.searchOpened.value) {
        lenis.value.stop();
    } else {
        lenis.value.start();
    }
});

if (!meta) {
    meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    const col = store?.headerColor?.value?.wave ?? store?.headerColor?.value?.over?.txt;
    if (col) {
        meta.setAttribute('content', col);
    }
    document.head.appendChild(meta);
}

// const headerColor = computed(() => {
//     const colors = store.headerColor;
//     if ((store.xs.value || store.md.value) && colors.theme !== 'reverse') {
//         colors.theme = 'light';
//     }

//     let headerTheme = 'light';
//     let headerCol = 'white';

//     if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//         headerTheme = 'dark';
//     }

//     const splitBg = colors.normal.bg.split('-');
//     const splitTxt = colors.normal.txt.split('-');
//     if (splitBg.length > 1) {
//         headerCol = splitBg[1];
//     }
//     if (headerCol === 'white' && splitTxt.length > 1) {
//         headerCol = splitTxt[1];
//     }

//     meta.setAttribute('content', headerbarColors[headerTheme][headerCol]);

//     return colors;
// });

watch(
    () => route.path,
    () => {
        navOpened.value = false;
        store.searchOpened.value = false;
    },
);
const { data } = await useQuery({
    variables,
    query: `query Fetch($lang: [String]) {
        main: navigationNodes (level: 1, navHandle: "main", site: $lang) {
            title
            id
            uri: nodeUri
            ... on main_Node {
                color
                subtitle
                subsubtitle
                suptitle
                featuredContentTitle
                featuredContentLinkText
                children {
                    id
                    title
                    uri: nodeUri
                    ... on main_Node {
                        icon: iconPicker {
                            inline
                        }
                        subtitle
                        suptitle
                    }
                }
            }
        },
        secondary: navigationNodes (navHandle: "secondary", site: $lang) {
            title,
            uri: nodeUri
            element { ... on EntryInterface { id, uri } }
        }
        infos: globalSet(handle: "footer") {
            ... on footer_GlobalSet {
                copyright: globalsFooterCopyright
            }
        },
        prenav: globalSet(handle: "prenav") {
            ... on prenav_GlobalSet {
                featuredContentTitle
            }
        },
        saison: navigationNodes (navHandle: "saison", site: $lang) {
            title,
            uri: nodeUri,
            ... on saison_Node {
                color
                subtitle
                suptitle
                personnageEntete {
                    inline
                }
                featuredContentLinkText
                featuredContentMainLinkText
                flexibleImage {
                    ... on uploads_Asset {
                        title,
                        url @transform (format: "webp", quality: 80, width: 500, height: 1000, immediately: true )
                    }
                }
                imageCover2 {
                    ... on uploads_Asset {
                        title,
                        url @transform (format: "webp", quality: 80, width: 500, height: 1000, immediately: true )
                    }
                }
                imageCover3 {
                    ... on uploads_Asset {
                        title,
                        url @transform (format: "webp", quality: 80, width: 500, height: 1000, immediately: true )
                    }
                }
            }
        }
    }`,
});

const closeAllMenus = (except = null) => {
    if (!data.value) return;

    for (let i = 0, il = data.value.main.length; i < il; ++i) {
        if (except && data.value.main[i].id !== except.id) {
            data.value.main[i].opened = false;
            data.value.main[i].mobileOpened = false;
        } else if (except == null) {
            data.value.main[i].opened = false;
            data.value.main[i].mobileOpened = false;
        }
    }

    if (except && except.opened) {
        navOpened.value = false;
    }
};

const toggle = () => {
    navOpened.value = !navOpened.value;

    if (navOpened.value) {
        closeAllMenus();
    }

    ga.send({
        category: navOpened.value ? 'headerMenu' : 'hamburgerMenu',
        action: 'clicInteract',
        label: navOpened.value ? 'openHamburger' : 'closeHamburger',
    });
};

const toggleTopLevelMenu = (link) => {
    const currentLink = link;

    ga.send({ label: currentLink.title });
    currentLink.opened = !currentLink.opened;

    closeAllMenus(link);
};

onMounted(() => {
    document.addEventListener('keyup', (evt) => {
        if (evt.keyCode === 27) {
            closeAllMenus();
        }
    });

    if (store.isBot.value) {
        return;
    }
    gsap.set(logo.value, { opacity: 0, y: -80 });
    const links = document.querySelectorAll('.PreNavigation, .Navigation__top-link, .Navigation__burger, .Navigation__search');
    gsap.set(links, { opacity: 0, y: -50, 'will-change': 'opacity,transform' });

    nextTick(() => {
        store.navready.value = true;

        if (links.length) {
            gsap.set(logo.value, { opacity: 0, y: -80 });
            // gsap.set(burger.value, { opacity: 0, scale: 0.9, rotation: 45 });

            if (location.value) {
                gsap.set(location.value, { opacity: 0, scale: 0.9, rotation: -45 });
            }

            const tl = gsap.timeline().to(logo.value, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo.out',
                clearProps: 'all',
            });

            // if (store.xl.value) {
            tl.to(
                links,
                {
                    duration: 1,
                    opacity: 1,
                    y: 0,
                    stagger: 0.08,
                    ease: 'expo.out',
                    clearProps: 'all',
                },
                '-=1',
            );
            // }

            // tl.to(
            //     burger.value,
            //     {
            //         duration: 1,
            //         opacity: 1,
            //         scale: 1,
            //         rotation: 0,
            //         ease: 'expo.out',
            //         clearProps: 'all',
            //     },
            //     '-=2',
            // );

            if (location.value) {
                tl.to(
                    location.value,
                    {
                        duration: 1,
                        opacity: 1,
                        scale: 1,
                        rotation: 0,
                        ease: 'expo.out',
                        clearProps: 'all',
                    },
                    '-=2',
                );
            }
            // window.setTimeout(() => {
            //     debugger;
            // }, 500);

            garbage.push(tl);
            // once = true;
        }
    });

    const col = store?.headerColor?.value?.wave ?? store?.headerColor?.value?.over?.txt;
    if (col) {
        meta.setAttribute('content', col);
    }
});

onUnmounted(() => {
    while (garbage.length) {
        garbage.pop().destroy();
    }
});

watch(store.isScrolledLarge, () => {
    if (store.isScrolledLarge.value) {
        closeAllMenus();
    }
});

const waveColorComputed = computed(() => (store.headerColor.wave ?? store.headerColor.over.txt));

closeAllMenus();
</script>

<template>
    <div
        v-if="data"
        ref="rootEl"
        class="Navigation fixed inset-x-0 top-0 z-48 w-full pb-3 transition-[transform] duration-500 print:hidden lg:w-screen"
        :class="{
            '-translate-y-14 md:-translate-y-12 lg:-translate-y-16 xl:-translate-y-12': store.isScrolledSmall.value,
            '-translate-y-14 md:-translate-y-32 lg:-translate-y-16 xl:-translate-y-32': store.isScrolledLarge.value,
        }"
    >
        <div
            class="PreNavigation relative z-49 hidden h-12 w-full items-center justify-between px-12 py-2.5 xl:flex"
            :class="[store.headerColor.prenav.bg, store.headerColor.prenav.txt]"
        >
            <div class="PreNavigation__tagline font-bold" v-html="data.prenav.featuredContentTitle" />
            <Socials class="PreNavigation__socials" type="footer" />
        </div>

        <div
            class="Navigation__wave absolute bottom-0 z-1 w-full transition duration-500"
            :class="{
                'xl:opacity-0': store.isScrolledLarge.value
            }"
        >
            <WaveTopSoft
                class="WaveTopSoft h-auto w-full fill-current"
                :class="['text-'+waveColorComputed]"
                :style="{
                    'opacity': Math.min(store.scrollTop.value / 400, 1)
                }"
            />
            <WaveTopSoftSmall
                class="WaveTopSoftSmall fill-current lg:hidden"
                :class="['text-'+waveColorComputed]"
                :style="{
                    'opacity': Math.min(store.scrollTop.value / 400, 1)
                }"
            />
        </div>

        <Loader
            class="LoaderMain !absolute left-1/2 top-18 z-48 -translate-x-1/2 xl:top-36"
            :bg="store.headerColor.normal.bg"
            :line="store.headerColor.normal.txt"
        />

        <div class="Navigation__banner relative z-48 w-full pt-3 print:hidden" :class="['is-' + store.headerColor.theme]">
            <div
                v-if="data"
                ref="top"
                class="Navigation__top flex w-full items-center justify-between px-6 font-medium xl:px-12"
                :class="[store.headerColor.nav.txt]"
            >
                <template v-for="(link, index) in data.main" :key="'mainnavlink'+link.title">
                    <div v-if="index == 3" class="Navigation__top-spacer hidden w-40 xl:block" />
                    <router-link
                        ref="topLevelLinks"
                        custom
                        active-class="router-link-active after:!scale-x-100"
                        :to="setUri({ uri: link.uri })"
                    >
                        <button
                            v-click-outside="
                                () => {
                                    closeAllMenus(link);
                                    navOpened = false;
                                }
                            "
                            class="Navigation__top-link group relative hidden tracking-tight xl:inline-block"
                            v-on:click.stop="toggleTopLevelMenu(link)"
                        >
                            <svg
                                class="triangle pointer-events-none absolute left-1/2 top-full h-auto w-6 -translate-x-1/2 transition duration-500"
                                width="24"
                                height="12"
                                viewBox="0 0 24 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                :class="{
                                    'translate-y-[6.8px] opacity-100 delay-300': link.opened,
                                    'translate-y-[calc(6.8px+1rem)] opacity-0': !link.opened,
                                }"
                            >
                                <path d="M11.6797 0L0.179688 11.5H23.1797L11.6797 0Z" fill="white" />
                            </svg>

                            <div class="relative overflow-hidden rounded-xl px-2 pb-3 pt-2 transition">
                                <div class="relative z-10">
                                    <span
                                        class="block text-center text-xxs leading-none transition group-hover:opacity-100"
                                        :class="{ 'opacity-100': link.opened, 'opacity-0': !link.opened }"
                                    >
                                        {{ link.suptitle }}
                                    </span>
                                    <span class="capitalize">{{ link.title }}</span>
                                </div>
                                <Caret
                                    :class="{ 'opacity-100': link.opened, 'opacity-0': !link.opened }"
                                    class="absolute bottom-1 left-1/2 z-2 mx-auto h-auto w-1 -translate-x-1/2 rotate-90 fill-current transition duration-500 group-hover:opacity-100"
                                />
                                <span
                                    class="pointer-events-none absolute inset-0 z-1 transition duration-500"
                                    :class="[store.headerColor.nav.hover, { 'opacity-100': link.opened, 'opacity-0 group-hover:opacity-45': !link.opened }]"
                                    aria-hidden
                                />
                            </div>
                        </button>
                    </router-link>

                    <transition
                        enter-active-class="transition duration-500"
                        leave-active-class="transition duration-500"
                        enter-from-class="opacity-0 translate-y-4"
                        enter-to-class="opacity-100 translate-y-0"
                        leave-from-class="opacity-100 translate-y-0"
                        leave-to-class="opacity-0 translate-y-4"
                        mode="out-in"
                        appear
                    >
                        <focus-trap :active="link.opened" :fallback-focus="() => topLevelLinks[index]">
                            <!-- shadow-dark-red/5 shadow-dark-blue/5 shadow-dark-yellow/5 shadow-dark-green/5 -->
                            <!-- shadow-dark-red/30 shadow-dark-blue/30 shadow-dark-yellow/30 shadow-dark-green/30 -->
                            <div
                                v-show="link.opened"
                                tabindex="-1"
                                class="sousmenu absolute top-[calc(100%+1rem)]"
                                :class="{
                                    'left-6 xl:left-12': index < 3,
                                    'right-6 xl:right-12': index >= 3,
                                }"
                            >
                                <div
                                    aria-hidden
                                    class="absolute top-8 z-1 hidden h-full w-full rounded-xl opacity-15 mix-blend-soft-light blur-2xl xl:block"
                                    :class="['bg-dark-' + link.color]"
                                />
                                <div
                                    aria-hidden
                                    class="absolute top-1 z-1 hidden h-full w-full rounded-xl opacity-15 mix-blend-soft-light blur-sm xl:block"
                                    :class="['bg-dark-' + link.color]"
                                />

                                <div
                                    class="sousmenu__inner relative z-10 overflow-hidden rounded-xl"
                                >
                                    <NavigationSubmenu class="relative bg-white px-3" :link="link" />
                                </div>
                            </div>
                        </focus-trap>
                    </transition>
                </template>

                <button
                    ref="searchButton"
                    class="Navigation__search mr-auto xl:mr-0"
                    v-on:click="toggleSearch"
                >
                    <MagnifyingGlass class="h-auto w-6 fill-current" />
                </button>

                <button
                    ref="burger"
                    class="Navigation__burger relative inline-block h-8 w-8 shrink-0 cursor-pointer rounded-full md:h-10 md:w-10 xl:h-11 xl:w-11"
                    v-on:click.stop="toggle"
                >
                    <svg
                        class="triangle pointer-events-none absolute left-1/2 top-full hidden h-auto w-6 -translate-x-1/2 transition duration-500 [--triangle-offset:1rem] md:block"
                        width="24"
                        height="12"
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="{
                            'translate-y-[var(--triangle-offset)] opacity-100 delay-300': navOpened,
                            'translate-y-[calc(var(--triangle-offset)+1rem)] opacity-0': !navOpened,
                        }"
                    >
                        <path d="M11.6797 0L0.179688 11.5H23.1797L11.6797 0Z" fill="white" />
                    </svg>

                    <svg
                        viewBox="0 0 50 50"
                        class="w-full"
                        fill-rule="evenodd"
                    >
                        <path
                            class="Navigation__burger-circle fill-current"
                            :class="[store.headerColor.nav.burger]"
                            d="M25,50 C20.0555001,50 15.2220001,48.5338001 11.1108001,45.7868001 C6.99953005,43.0397001 3.79521005,39.1352001 1.90302005,34.5671001 C0.0108321539,29.9989001 -0.484250946,24.9723001 0.480379054,20.1228001 C1.44501005,15.2732001 3.82603005,10.8187001 7.32234005,7.32234005 C10.8187001,3.82603005 15.2732001,1.44501005 20.1228001,0.480379054 C24.9723001,-0.484250946 29.9989001,0.0108321539 34.5671001,1.90302005 C39.1352001,3.79521005 43.0397001,6.99953005 45.7868001,11.1108001 C48.5338001,15.2220001 50,20.0555001 50,25 C50,31.6304001 47.3661001,37.9893001 42.6777001,42.6777001 C37.9893001,47.3661001 31.6304001,50 25,50 L25,50"
                        />
                        <path
                            class="Navigation__burger-open fill-current"
                            :class="[{ 'opacity-0': navOpened, 'opacity-100': !navOpened }, store.headerColor.nav.lines]"
                            d="M34.0000001,20.0000001L34.0000001,20.0000001C34.2652001,20.0000001 34.5196001,19.8946001 34.7071001,19.7071001C34.8946001,19.5196001 35.0000001,19.2652001 35.0000001,19.0000001C35.0000001,18.7348001 34.8946001,18.4804001 34.7071001,18.2929001C34.5196001,18.1054001 34.2652001,18.0000001 34.0000001,18.0000001L16.0000001,18.0000001C15.7348001,18.0000001 15.4804001,18.1054001 15.2929001,18.2929001C15.1054001,18.4804001 15.0000001,18.7348001 15.0000001,19.0000001C15.0000001,19.2652001 15.1054001,19.5196001 15.2929001,19.7071001C15.4804001,19.8946001 15.7348001,20.0000001 16.0000001,20.0000001L34.0000001,20.0000001"
                        />
                        <path
                            class="Navigation__burger-open fill-current"
                            :class="[{ 'opacity-0': navOpened, 'opacity-100': !navOpened }, store.headerColor.nav.lines]"
                            d="M34.0000001,26.0000001L34.0000001,26.0000001C34.2652001,26.0000001 34.5196001,25.8946001 34.7071001,25.7071001C34.8946001,25.5196001 35.0000001,25.2652001 35.0000001,25.0000001C35.0000001,24.7348001 34.8946001,24.4804001 34.7071001,24.2929001C34.5196001,24.1054001 34.2652001,24.0000001 34.0000001,24.0000001L16.0000001,24.0000001C15.7348001,24.0000001 15.4804001,24.1054001 15.2929001,24.2929001C15.1054001,24.4804001 15.0000001,24.7348001 15.0000001,25.0000001C15.0000001,25.2652001 15.1054001,25.5196001 15.2929001,25.7071001C15.4804001,25.8946001 15.7348001,26.0000001 16.0000001,26.0000001L34.0000001,26.0000001"
                        />
                        <path
                            class="Navigation__burger-open fill-current"
                            :class="[{ 'opacity-0': navOpened, 'opacity-100': !navOpened }, store.headerColor.nav.lines]"
                            d="M34.0000001,32.0000001L34.0000001,32.0000001C34.2652001,32.0000001 34.5196001,31.8946001 34.7071001,31.7071001C34.8946001,31.5196001 35.0000001,31.2652001 35.0000001,31.0000001C35.0000001,30.7348001 34.8946001,30.4804001 34.7071001,30.2929001C34.5196001,30.1054001 34.2652001,30.0000001 34.0000001,30.0000001L16.0000001,30.0000001C15.7348001,30.0000001 15.4804001,30.1054001 15.2929001,30.2929001C15.1054001,30.4804001 15.0000001,30.7348001 15.0000001,31.0000001C15.0000001,31.2652001 15.1054001,31.5196001 15.2929001,31.7071001C15.4804001,31.8946001 15.7348001,32.0000001 16.0000001,32.0000001L34.0000001,32.0000001"
                        />
                        <path
                            class="Navigation__burger-close fill-current"
                            :class="[{ 'opacity-0': !navOpened, 'opacity-100': navOpened }, store.headerColor.nav.lines]"
                            d="M32.6,19.5L18.8,32c-0.4,0.4-1.1,0.4-1.4,0c-0.4-0.4-0.3-1.1,0.1-1.4L31.2,18c0.4-0.4,1.1-0.4,1.4,0 C33,18.5,33,19.1,32.6,19.5z"
                        />
                        <path
                            class="Navigation__burger-close fill-current"
                            :class="[{ 'opacity-0': !navOpened, 'opacity-100': navOpened }, store.headerColor.nav.lines]"
                            d="M30.6,32.5L17.9,18.9c-0.4-0.4-0.4-1.1,0-1.4s1-0.3,1.4,0.1l12.8,13.5c0.4,0.4,0.4,1.1,0,1.4C31.7,32.9,31,32.9,30.6,32.5z"
                        />
                    </svg>
                </button>
            </div>

            <!--
            <div class="Navigation__location fixed bg-dark-blue left-4 top-4 w-8 h-8 rounded-full flex items-center justify-center transition duration-500"
                :class="{
                    '!bg-white'store.:headerColor.theme == 'reverse'
                }"
             ref="location" v-if="portrait">
                <router-link
                    @click="ga.send({ label: 'rabaisCta' })"
                    :to="{ name: 'discounts.' + lang }"
                    class="text-white"
                    :class="{
                        '!text-dark-blue'store.:headerColor.theme == 'reverse'
                    }"
                    >
                    <Icon type="dollar" svgclass="block transition duration-500 fill-current w-2 h-auto" />
                </router-link>
            </div>
            -->

            <div
                ref="logo"
                class="Navigation__logo absolute left-1/2 top-0 -translate-x-1/2"
                v-on:click="ga.send({ label: 'logo' })"
            >
                <LogoLetter v-if="landscape || tablet" :colors="store.headerColor.normal" />
                <div ref="reverse" class="Navigation__logo-reverse">
                    <LogoLetter ref="logo" :colors="store.headerColor.over" />
                </div>
            </div>

            <NavigationLightbox :menu="data" :opened="navOpened" />
        </div>
    </div>
</template>
