/* eslint-disable */

// https://github.com/jonkwheeler/ScrollScene/issues/18

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import throttle from 'lodash.throttle';
import { errorLog, isObject, scrollAnimationInit } from "../../../node_modules/scrollscene/dist/esm/helpers/index";
import ScrollMagic from 'scrollmagic';
var nameSpace = 'ScrollScene';

var updateTweenProgress = function updateTweenProgress(Scene, Tween, gsapForwardSpeed, gsapReverseSpeed) {
  if (Tween) {
    var progress = Scene.progress();
    var state = Scene.state();

    if (Tween.repeat && Tween.repeat() === -1) {
      if (state === 'DURING' && Tween.paused()) {
        Tween.timeScale(gsapForwardSpeed).play();
      } else if (state !== 'DURING' && !Tween.paused()) {
        Tween.pause();
      }
    } else if (progress != Tween.progress()) {
      if (Scene.duration() === 0) {
        if (progress > 0) {
          Tween.timeScale(gsapForwardSpeed).play();
        } else {
          Tween.timeScale(gsapReverseSpeed).reverse();
        }
      } else {
        Tween.progress(progress).pause();
      }
    }
  }
};

var removeTween = function removeTween(Tween) {
  if (Tween) {
    Tween.pause(0);
    Tween.kill();
  }
};

var setDuration = function setDuration(Scene, duration) {
  if (duration instanceof HTMLElement) {
    var previousHeight;
    var currentHeight;

    var getHeight = function getHeight() {
      return duration.offsetHeight;
    };

    var update = function update() {
      Scene.duration(getHeight());
      previousHeight = getHeight();
    };

    var fn = function fn() {
      currentHeight = getHeight();

      if (currentHeight !== previousHeight) {
        update();
      }
    };

    fn();
    window.addEventListener('resize', throttle(fn, 700));
    currentHeight = getHeight();
    update();
  } else if (isObject(duration)) {
    var keys = Object.keys(duration).reverse();

    var _fn = function _fn() {
      for (var index = 0; index < keys.length; index++) {
        var breakpoint = parseFloat(keys[index]);

        if (breakpoint <= window.innerWidth) {
          Scene.duration(duration[breakpoint]);
          break;
        }
      }
    };

    _fn();

    window.addEventListener('resize', throttle(_fn, 700));
  } else {
    Scene.duration(duration);
  }
};

var setClassName = function setClassName(Scene, options, duration) {
  var toggle = _objectSpread({
    className: null,
    element: null,
    reverse: false
  }, options);

  if (!toggle.className) {
    errorLog(nameSpace, "Be sure to set a className in the new ".concat(nameSpace, "({ toggle: { className: \"my-class\" } })"));
  }

  if (!toggle.element) {
    errorLog(nameSpace, "Be sure to set a const toggleElement = (reactRef.current or document.querySelector) in the new ".concat(nameSpace, "({ toggle: { element: toggleElement } })"));
  }

  var addClassName = function addClassName() {
    return !toggle.element.classList.contains(toggle.className) && toggle.element.classList.add(toggle.className);
  };

  var removeClassName = function removeClassName() {
    return toggle.element.classList.contains(toggle.className) && toggle.element.classList.remove(toggle.className);
  };

  Scene.on('enter', function () {
    addClassName();
  });
  Scene.on('add', function () {
    if (Scene.state() === 'DURING') {
      addClassName();
    }
  });
  Scene.on('leave', function (event) {
    if (!toggle.reverse && duration) {
      event.scrollDirection === 'REVERSE' && removeClassName();
    } else {
      removeClassName();
    }
  });
  Scene.on('remove', function () {
    removeClassName();
  });
};

var setTween = function setTween(Scene, options) {
  var gsap = _objectSpread({
    forwardSpeed: 1,
    reverseSpeed: 1,
    timeline: null
  }, options);

  if (!gsap.timeline) {
    errorLog(nameSpace, "Be sure to set a const tl = gsap.timeline({ paused: true }) in the new ".concat(nameSpace, "({ gsap: { timeline: tl } })"));
  }

  Scene.on('progress', function () {
    updateTweenProgress(Scene, gsap.timeline, gsap.forwardSpeed, gsap.reverseSpeed);
  });
  Scene.on('remove', function () {
    removeTween(gsap.timeline);
  });
};

var globalController;

var ScrollScene = function ScrollScene(_ref) {
  var breakpoints = _ref.breakpoints,
      _ref$controller = _ref.controller,
      controller = _ref$controller === void 0 ? {} : _ref$controller,
      duration = _ref.duration,
      gsap = _ref.gsap,
      _ref$offset = _ref.offset,
      offset = _ref$offset === void 0 ? 0 : _ref$offset,
      _ref$scene = _ref.scene,
      scene = _ref$scene === void 0 ? {} : _ref$scene,
      toggle = _ref.toggle,
      triggerElement = _ref.triggerElement,
      _ref$triggerHook = _ref.triggerHook,
      triggerHook = _ref$triggerHook === void 0 ? 'onEnter' : _ref$triggerHook,
      _ref$useGlobalControl = _ref.useGlobalController,
      useGlobalController = _ref$useGlobalControl === void 0 ? true : _ref$useGlobalControl;
  var localController;

  if (!useGlobalController) {
    localController = new ScrollMagic.Controller(controller);
  }

  if (!globalController && useGlobalController) {
    globalController = new ScrollMagic.Controller(controller);
  }

  var controllerIsUse = localController ? localController : globalController;

  if (!triggerElement) {
    errorLog(nameSpace, "Be sure to set a const triggerElement = (reactRef.current or document.querySelector) in the new ".concat(nameSpace, "({ triggerElement: triggerElement })."));
  }

  var Scene = new ScrollMagic.Scene(_objectSpread({
    triggerElement: triggerElement,
    triggerHook: triggerHook,
    offset: offset
  }, scene));

  if (duration) {
    setDuration(Scene, duration);
  }

  if (toggle && isObject(toggle)) {
    setClassName(Scene, toggle, duration);
  }

  if (gsap && isObject(gsap)) {
    setTween(Scene, gsap);
  }

  this.init = function () {
    controllerIsUse && Scene.addTo(controllerIsUse);
  };

  this.destroy = function () {
    Scene.remove();
  };

  this.Scene = Scene;
  this.Controller = controllerIsUse;
  scrollAnimationInit(breakpoints, this.init, this.destroy);
};

export { ScrollScene };