import { ref, reactive } from 'vue';
import Settings from '@/config/Settings';
import { useLenis } from '@/components/scroll/Lenis.js';

const windowSizes = ref({ width: 0, height: 0 });
const windowScroll = ref({ y: 0, x: 0 });
const route = ref({
    from: {
        name: null,
        meta: {
            lang: Settings.locale[0].lang,
            name: null,
        },
    },
    to: {
        name: null,
        meta: {
            lang: Settings.locale[0].lang,
            name: null,
        },
    },
});
const lang = ref(Settings.locale[0].lang);
const headerColor = reactive({
    mainColor: 'blue',
    theme: 'light',
    normal: { bg: 'white', txt: 'light-blue' },
    over: { bg: 'dark-blue', txt: 'white' },
    prenav: {
        bg: 'bg-light-blue',
        txt: 'text-dark-blue',
    },
    nav: { burger: 'text-dark-blue', lines: 'text-white', txt: 'text-dark-blue' },
});
const baseName = window.baseName || 'http://quebecbio.test';
const isBot = ref(false);
// Sizings based on Tailwind's
const xl = ref(false);
const md = ref(false);
const xs = ref(false);
const showPopupNewsletter = ref(false);
const navready = ref(false);
const ui = ref({});

const commitWindowSize = (e) => {
    windowSizes.value = {
        width: window.innerWidth,
        height: window.innerHeight,
    };
    xl.value = window.innerWidth >= 1280;
    md.value = window.innerWidth >= 801 && window.innerWidth < 1280;
    xs.value = window.innerWidth < 801;
};

const commitIsBot = (e) => {
    const botRegex = /bot|googlebot|crawler|spider|robot|crawling|prerender|lighthouse/i;
    const isBotTest = navigator.userAgent && botRegex.test(navigator.userAgent);

    isBot.value = isBotTest;
};

let timeoutRef = null;
const debouncedResize = (e) => {
    if (timeoutRef !== null) {
        window.clearTimeout(timeoutRef);
    }

    timeoutRef = window.setTimeout(() => {
        commitWindowSize(e);
    }, 200);
};

document.addEventListener('DOMContentLoaded', commitWindowSize);
document.addEventListener('DOMContentLoaded', commitIsBot);
window.addEventListener('resize', debouncedResize);

const lenis = useLenis();
const scrollTop = ref(0);
const scrollDirection = ref(0);
const pin = ref(0);
const pinnedDistance = ref(0);
const isScrolledSmall = ref(false);
const isScrolledLarge = ref(false);
lenis.value.on('scroll', () => {
    scrollTop.value = lenis.value.scroll;
    if (scrollDirection.value != lenis.value.direction) {
        pin.value = scrollTop.value;
    }
    scrollDirection.value = lenis.value.direction;
    pinnedDistance.value = Math.abs(pin.value - scrollTop.value);

    windowScroll.value = {
        y: lenis.value.scroll,
        x: document.documentElement.scrollLeft,
    };

    isScrolledSmall.value = scrollTop.value > 400 && pinnedDistance.value > 200 && pinnedDistance.value < 1200 && scrollDirection.value == 1;
    isScrolledLarge.value = scrollTop.value > 400 && pinnedDistance.value > 1200 && scrollDirection.value == 1;
});

const searchOpened = ref(false);

const setHeaderColor = (colors) => {
    const keys = Object.keys(colors);
    for (let i = 0, il = keys.length; i < il; ++i) {
        const key = keys[i];
        headerColor[key] = colors[key];
    }
};

const isLoading = ref(false);

export const useStore = () => ({
    lang,
    route,
    setHeaderColor,
    headerColor,
    windowSizes,
    windowScroll,
    ui,
    xl,
    md,
    xs,
    showPopupNewsletter,
    navready,
    isBot,
    baseName,
    scrollTop,
    scrollDirection,
    pin,
    pinnedDistance,
    isScrolledSmall,
    isScrolledLarge,
    searchOpened,
    isLoading,
});
