<script setup>
import {
    watch, computed, ref, nextTick, onMounted,
} from 'vue';
import { useQuery } from 'villus';
import { gsap } from 'gsap';

import { GA } from '@/mixins/Ga';
import { useStore } from '@/store/Store';

const props = defineProps({
    type: {
        type: String,
        default: '',
    },
});
let gaCategoryType = 'footerMenu';
switch (props.type) {
case 'navLightbox':
    gaCategoryType = 'hamburgerMenu';
    break;
case 'contact':
    gaCategoryType = 'bottomContact';
    break;
default:
    gaCategoryType = 'footerMenu';
}

const ga = new GA({
    category: gaCategoryType,
    action: 'clicSocialExterne',
});

const store = useStore();
const variables = computed(() => ({ lang: store.lang.value }));
const rootEl = ref(null);

const { data } = await useQuery({
    variables,
    query: `query FetchEntries($lang: [String]) {
        socials: globalSet(handle: "socials", site: $lang) {
            ... on socials_GlobalSet {
                twitter: globalsSocialsTwitter,
                youtube: globalsSocialsYoutube,
                facebook: globalsSocialsFacebook,
                instagram: globalsSocialsInstagram
            }
        }
    }`,
});

onMounted(() => {
    nextTick(() => {
        gsap.set('.Socials__li', { opacity: 0, y: -80 });
        nextTick(() => {
            gsap.to('.Socials__li', {
                duration: 1,
                opacity: 1,
                y: 0,
                stagger: 0.08,
                ease: 'expo.out',
                clearProps: 'all',
            });
        });
    });
});
</script>

<template>
    <ul
        ref="rootEl"
        class="Socials groupnot flex gap-6"
        :class="['is-' + type]"
    >
        <template v-if="data">
            <li class="Socials__li inline-flex items-center">
                <a
                    class="Socials__btn inline-flex items-center transition duration-500 groupnot-hover:opacity-50"
                    target="_blank"
                    :href="data.socials.instagram"
                    v-on:click="ga.send({ label: 'instagram' })"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        class="h-5 w-auto fill-current md:h-6"
                        viewBox="0 0 32 32"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M32 3.809C32 1.707 30.248 0 28.09 0H3.91C1.752 0 0 1.707 0 3.809v23.557c0 2.102 1.752 3.809 3.91 3.809h24.18c2.158 0 3.91-1.707 3.91-3.809V3.81zM11.465 5.063C12.638 5.012 13.013 5 16 5s3.362.012 4.535.063c1.171.05 1.97.227 2.67.486a5.43 5.43 0 0 1 1.949 1.205c.611.58.987 1.164 1.268 1.85.272.666.458 1.425.512 2.538.053 1.114.066 1.47.066 4.308s-.013 3.194-.066 4.309c-.054 1.112-.24 1.871-.512 2.536a5.104 5.104 0 0 1-1.268 1.851 5.43 5.43 0 0 1-1.949 1.205c-.7.259-1.499.435-2.67.486-1.173.051-1.548.063-4.535.063s-3.362-.012-4.535-.063c-1.171-.05-1.97-.227-2.67-.486a5.429 5.429 0 0 1-1.949-1.205 5.104 5.104 0 0 1-1.269-1.85c-.271-.666-.457-1.425-.51-2.537C5.012 18.644 5 18.288 5 15.45s.013-3.194.066-4.308c.054-1.113.24-1.872.511-2.537a5.104 5.104 0 0 1 1.27-1.851 5.429 5.429 0 0 1 1.948-1.205c.7-.259 1.499-.435 2.67-.486zm8.98 1.88c-1.16-.05-1.508-.06-4.445-.06-2.937 0-3.285.01-4.445.06-1.072.047-1.655.218-2.042.36-.514.19-.88.417-1.265.782a3.227 3.227 0 0 0-.823 1.202c-.15.368-.33.921-.379 1.94-.053 1.102-.064 1.433-.064 4.223s.011 3.12.064 4.223c.049 1.019.228 1.572.379 1.94.2.488.438.836.823 1.202.385.365.751.592 1.265.781.387.143.97.314 2.042.36 1.16.05 1.508.061 4.445.061 2.937 0 3.285-.01 4.445-.06 1.072-.047 1.655-.218 2.043-.36a3.43 3.43 0 0 0 1.264-.782c.385-.366.624-.714.823-1.202.15-.368.33-.921.379-1.94.053-1.102.064-1.433.064-4.223s-.011-3.12-.064-4.223c-.049-1.019-.228-1.572-.379-1.94a3.227 3.227 0 0 0-.823-1.202 3.431 3.431 0 0 0-1.264-.781c-.388-.143-.97-.314-2.043-.36zm1.427 4.183c.729 0 1.32-.562 1.32-1.254 0-.693-.591-1.254-1.32-1.254-.729 0-1.32.561-1.32 1.254 0 .692.591 1.254 1.32 1.254zM16 10.084c-3.12 0-5.649 2.402-5.649 5.366 0 2.964 2.53 5.366 5.649 5.366 3.12 0 5.649-2.402 5.649-5.366 0-2.964-2.53-5.366-5.649-5.366zm0 8.85c-2.025 0-3.667-1.56-3.667-3.484s1.642-3.483 3.667-3.483 3.667 1.56 3.667 3.483c0 1.924-1.642 3.483-3.667 3.483z"
                            clip-rule="evenodd"
                        />
                    </svg>

                </a>
            </li>
            <li class="Socials__li inline-flex items-center">
                <a
                    class="Socials__btn  inline-flex items-center transition duration-500 groupnot-hover:opacity-50"
                    target="_blank"
                    :href="data.socials.facebook"
                    v-on:click="ga.send({ label: 'facebook' })"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        class="h-5 w-auto fill-current md:h-6"
                        viewBox="0 0 32 32"
                    >
                        <path d="M28.09 0C30.248 0 32 1.707 32 3.809v23.557c0 2.102-1.752 3.809-3.91 3.809h-6.654V19.429H25.6l.792-5.031h-4.955v-3.265c0-1.376.692-2.718 2.911-2.718H26.6V4.133s-2.044-.34-3.999-.34c-4.08 0-6.747 2.409-6.747 6.77v3.835h-4.536v5.03h4.536v11.747H3.91c-2.158 0-3.91-1.707-3.91-3.809V3.81C0 1.707 1.752 0 3.91 0h24.18z" />
                    </svg>

                </a>
            </li>
            <li class="Socials__li inline-flex items-center">
                <a
                    class="Socials__btn  inline-flex items-center transition duration-500 groupnot-hover:opacity-50"
                    target="_blank"
                    :href="data.socials.twitter"
                    v-on:click="ga.send({ label: 'twitter' })"
                >
                    <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    class="h-5 w-auto fill-current md:h-6"
                    viewBox="0 0 32 32"
                >
                    <path d="M28.09 0C30.248 0 32 1.707 32 3.809v23.557c0 2.102-1.752 3.809-3.91 3.809H3.91c-2.158 0-3.91-1.707-3.91-3.809V3.81C0 1.707 1.752 0 3.91 0h24.18zM12.22 25.85c8.496 0 13.142-6.859 13.142-12.803 0-.196 0-.392-.01-.579a9.331 9.331 0 0 0 2.309-2.332 9.57 9.57 0 0 1-2.654.709 4.52 4.52 0 0 0 2.03-2.492 9.502 9.502 0 0 1-2.93 1.092 4.654 4.654 0 0 0-3.372-1.418c-2.548 0-4.617 2.015-4.617 4.497 0 .355.039.7.125 1.027a13.22 13.22 0 0 1-9.521-4.703 4.425 4.425 0 0 0-.623 2.258 4.45 4.45 0 0 0 2.06 3.742 4.629 4.629 0 0 1-2.089-.56v.056c0 2.184 1.59 3.994 3.707 4.414a4.716 4.716 0 0 1-2.088.075c.585 1.791 2.29 3.088 4.31 3.126a9.441 9.441 0 0 1-5.737 1.922c-.373 0-.737-.019-1.101-.065a13.115 13.115 0 0 0 7.059 2.034z" />
                </svg> -->
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="none"
                        viewBox="0 0 32 32"
                        class="h-5 w-auto fill-current md:h-6"
                    >
                        <path d="M20.618 24.697h2.846L10.366 6.365H7.52l13.098 18.332z" />
                        <path
                            fill-rule="evenodd"
                            d="M32 3.809C32 1.707 30.248 0 28.09 0H3.91C1.752 0 0 1.707 0 3.81v23.556c0 2.102 1.752 3.809 3.91 3.809h24.18c2.158 0 3.91-1.707 3.91-3.809V3.809zM25.315 5l-7.817 8.892L26 26h-6.253l-5.726-8.153L6.853 26H5l8.199-9.325L5 5h6.253l5.422 7.72L23.463 5h1.852z"
                            clip-rule="evenodd"
                        />
                    </svg>

                </a>
            </li>
            <li class="Socials__li inline-flex items-center">
                <a
                    class="Socials__btn  inline-flex items-center transition duration-500 groupnot-hover:opacity-50"
                    target="_blank"
                    :href="data.socials.youtube"
                    v-on:click="ga.send({ label: 'youtube' })"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        class="h-5 w-auto fill-current md:h-6"
                        viewBox="0 0 32 32"
                    >
                        <path d="M20.175 15.5L14.2 12.286v6.429l5.975-3.215z" />
                        <path
                            fill-rule="evenodd"
                            d="M32 3.809C32 1.707 30.248 0 28.09 0H3.91C1.752 0 0 1.707 0 3.809v23.557c0 2.103 1.752 3.809 3.91 3.809h24.18c2.158 0 3.91-1.706 3.91-3.809V3.81zm-6.514 4.64c.99.245 1.769.97 2.034 1.893C28 12.014 28 15.5 28 15.5s0 1.065-.081 2.3c-.066 1-.185 2.11-.4 2.857-.264.923-1.043 1.649-2.033 1.896C23.692 23 16.5 23 16.5 23s-1.914 0-4.014-.061c-1.97-.058-4.104-.17-4.972-.386-.99-.247-1.769-.973-2.034-1.896C5 18.987 5 15.5 5 15.5s0-3.486.48-5.158c.265-.922 1.044-1.648 2.034-1.894C9.308 8 16.5 8 16.5 8s7.192 0 8.986.448z"
                            clip-rule="evenodd"
                        />
                    </svg>

                </a>
            </li>
        </template>
    </ul>
</template>
