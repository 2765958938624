import {
    computed, watch, ref,
} from 'vue';
import { useQuery } from 'villus';
import find from 'lodash-es/find';
import each from 'lodash-es/each';
import { useRoute } from 'vue-router';
import Settings from '@/config/Settings';
import { useStore } from '@/store/Store';
// import { useMeta } from 'vue-meta'

const useSeo = () => {
    const store = useStore();
    const route = useRoute();

    const seo = ref({
        title: '',
        description: '',
    });

    // useMeta({
    //   title: seo.title,
    //   htmlAttrs: { lang: store.lang.value }
    // })

    const variables = computed(() => ({
        path: route.path,
        uri: route.path.replace(`/${store.lang.value}`, ''),
        siteId: find(Settings.locale, (l) => l.lang === store.lang.value).id,
    }));

    const { data } = useQuery({
        variables,
        query: `
    query FetchEntries($uri: String, $siteId: Int) {
        seomatic (uri: $uri, siteId: $siteId, asArray: true) {
            metaTitleContainer
        }
    }
`,
    });

    watch(data, () => {
        if (!data.value) return;
        each(data.value.seomatic, (v, k) => {
            if (typeof v === 'string') data.value.seomatic[k] = JSON.parse(v);
        });
        seo.value.title = data.value.seomatic.metaTitleContainer.title.title;
        document.title = seo.value.title;
    });
};

export default useSeo;
