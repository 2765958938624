<script setup>
import { useAttrs } from 'vue';

const inheritedAttrs = useAttrs();
const attrs = { ...inheritedAttrs };

defineOptions({
    inheritAttrs: false,
});

if (!attrs.class) {
    attrs.class = 'bg-dark-blue text-white';
}

defineProps({
    text: { default: '', type: String },
    size: { default: 'normal', type: String },
    align: { default: 'normal', type: String },
    up: { default: false, type: Boolean },
    y: { default: 0, type: Number },
    x: { default: 0, type: Number },
    icon: { default: 'arrow', type: String },
});
</script>

<template>
    <div
        v-bind="attrs"
        class="Btn group inline-flex items-center rounded-xl px-4 py-2 font-medium"
        :class="['is-' + size, 'is-' + align, {
            'is-up': up,
            'justify-end': align == 'right',
            'text-sm': size == 'small',
            'text-base': size == 'normal'
        }]"
    >
        <span class="Btn__text" :class="{'mr-2': icon}">{{ text }}</span>
        <Icon
            v-if="icon"
            class="ml-auto w-4 transition duration-500 group-hover:translate-x-2"
            :type="icon"
            svgclass="fill-current"
            :class="{
                '-rotate-45': up
            }"
        />
    </div>
</template>
