<script setup>
import { gsap } from 'gsap';

const enter = (element, done) => {
    gsap.to(element, {
        height: 'auto',
        duration: 0.5,
        onComplete: done,
    });
};

const leave = (element, done) => {
    gsap.to(element, {
        height: '0',
        duration: 0.5,
        onComplete: done,
    });
};
</script>
<template>
    <transition
        name="expand"
        :css="false"
        v-on:enter="enter"
        v-on:leave="leave"
    >
        <slot />
    </transition>
</template>
