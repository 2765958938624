<script setup>
import { computed } from 'vue';

const props = defineProps({
    data: { default: () => [], type: Array },
    clipPath: { default: true, type: Boolean },
    color: { default: 'transparent', type: String },
    fit: { default: 'cover', type: String },
    credits: { default: '', type: String },
    imgclass: { default: '', type: String },
    creditsclass: { default: '', type: String },
});

const cover = computed(() => {
    if (props.data.length > 0) {
        return {
            url: props.data[0].url,
            title: props.data[0].title,
            credits: props.credits,
            width: props.data[0].width,
            height: props.data[0].height,
            srcset: props.data[0]?.srcset,
        };
    }
    return {
        url: '',
        title: '',
        credits: '',
        width: null,
        height: null,
        srcset: null,
    };
});

</script>

<template>
    <div class="Cover relative h-full w-full" :class="['is-' + color, { 'has-clip': clipPath }]">
        <div class="Cover__clip relative h-full w-full overflow-hidden rounded-xl">
            <img
                ref="image"
                loading="lazy"
                class="Cover__image block h-full w-full bg-center bg-no-repeat transition duration-500"
                :class="[
                    'is-' + fit,
                    {
                        'bg-cover object-cover': fit == 'cover',
                        'bg-contain object-contain': fit == 'contain',
                    },
                    imgclass,
                ]"
                :alt="cover.title"
                :width="cover.width"
                :height="cover.height"
                :src="cover.url"
                :srcset="cover.srcset"
            >
        </div>
        <div
            v-if="cover.credits"
            class="Cover__credits my-2 text-xs font-normal"
            :class="creditsclass"
            v-html="cover.credits"
        />
    </div>
</template>
