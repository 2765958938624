<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import Cookies from 'js-cookie';

const theroute = useRoute();
const cookieDecision = ref(true);
const cookieValue = ref(false);
const isPageCheck = () => theroute.path === '/pages/politique-de-confidentialite';
const isPage = computed(() => isPageCheck());
cookieValue.value = Cookies.get('cookieDecision');

const successMessage = ref('');

const setAcceptance = (val) => {
    Cookies.set('cookieDecision', val, { expires: 730 }); // 2 years

    if (isPageCheck()) {
        successMessage.value = 'Vos préférences ont été sauvegardées avec succès.';
    } else {
        window.setTimeout(() => {
            window.location.reload();
        }, 250);
    }

    cookieDecision.value = val;
};

if (typeof cookieValue.value !== 'undefined') {
    cookieDecision.value = JSON.parse(cookieValue.value);
}

// when our checks are done, we set cookie by default to 1 to allow executing our tags on gtag manager
if (typeof cookieValue.value === 'undefined') {
    Cookies.set('cookieDecision', true, { expires: 730 }); // 2 years
}
</script>

<template>
    <div
        v-if="typeof cookieValue === 'undefined' || isPage"
        class="CookieConsent bg-dark-blue m-0 flex w-full flex-col pt-6 align-baseline leading-6 text-white"
        :class="{
            'sm:flex-col sm:items-start sm:rounded-none': isPage,
            'fixed bottom-0 right-0 z-50 -mt-8 max-w-[725px] p-4 sm:flex-row sm:items-center sm:rounded-tl-2xl': !isPage,
        }"
    >
        <div
            class="CookieConsent-message"
            :class="[isPage ? 'mx-auto mb-4 max-w-5xl' : 'text-xxs mr-auto']"
        >
            <div v-if="successMessage !== ''" class="CookieConsent-success text-light-green">
                {{ successMessage }}
            </div>
            <div>
                <span v-if="isPage">Ce site utilise des cookies. Sélectionnez une des options suivantes pour modifier vos préférences.
                </span>
                <span v-else>Ce site utilise des cookies. En naviguant sur ce site, vous en acceptez leur utilisation.
                </span>
                <span v-if="!isPage">Pour plus d'informations, consultez notre
                    <a
                        class="policy-link underline"
                        href="/pages/politique-de-confidentialite"
                    >politique de confidentialité</a>.</span>
            </div>
        </div>
        <div class="CookieConsent-buttons mt-4 flex justify-center md:mt-0" :class="[isPage ? 'mx-auto mb-4 max-w-5xl' : 'text-xxs mr-auto']">
            <a
                href="#nogo"
                class="CookieConsent-button CookieConsent-button-accept btn mr-2"
                :class="[isPage ? 'text-base' : 'btn-is-small']"
                v-on:click.prevent="setAcceptance(true)"
            >
                <span v-if="isPage && cookieDecision == true" class="mr-2">&check;</span>
                <span>Accepter</span>
            </a>
            <a
                v-if="isPage"
                href="#nogo"
                class="CookieConsent-button CookieConsent-button-refuse btn bg-light-blue mr-2"
                :class="[isPage ? 'text-base' : 'btn-is-small']"
                v-on:click.prevent="setAcceptance(false)"
            >
                <span v-if="isPage && !cookieDecision" class="mr-2">&check;</span>
                <span>Refuser</span>
            </a>
        </div>
    </div>
</template>
