<script setup>
import {
    onUnmounted, ref, nextTick, onMounted,
} from 'vue';
import { gsap } from 'gsap';
import each from 'lodash-es/each';

import { ScrollScene } from '@/commons/js/ScrollScene';
import { GA } from '@/mixins/Ga';
import { useLenis } from '@/components/scroll/Lenis.js';

const lenis = useLenis();
const rootEl = ref(null);
const garbage = [];
const pageScrolled = ref(false);

onMounted(() => {
    nextTick(() => {
        rootEl.value.classList.add('init');

        const scene = new ScrollScene({
            triggerElement: '.Page',
            triggerHook: 0,
            offset: 400,
        });

        scene.Scene.on('enter', () => {
            pageScrolled.value = true;
        });
        scene.Scene.on('leave', () => {
            pageScrolled.value = false;
        });

        garbage.push(scene);

        const pin = new ScrollScene({
            triggerHook: 1,
            triggerElement: '.Footer',
        });
        pin.Scene.on('enter', () => {
            gsap.to(rootEl.value, { autoAlpha: 0 });
        });
        pin.Scene.on('leave', () => {
            gsap.to(rootEl.value, { autoAlpha: 1 });
        });

        garbage.push(pin);
    });
});

onUnmounted(() => {
    while (garbage.length) {
        garbage.pop().destroy();
    }
});

const toTop = () => {
    const pageContent = document.querySelector('.Page__content');
    if (pageContent) {
        lenis.value.scrollTo(pageContent.offsetTop - 160);
    } else {
        lenis.value.scrollTo(0);
    }
};

const darkViews = ['faq', 'faqs', 'discount', 'blogs', 'blog', 'recettes', 'recette'];
const hiddenViews = ['bio'];
</script>

<template>
    <div
        ref="rootEl"
        class="ScrollTop group fixed bottom-7 left-8 z-10 flex h-5 w-5 cursor-pointer items-center justify-center transition-all duration-1000 print:hidden xl:left-11"
        :class="[
            'is-' + $route.meta.name,
            {
                'is-mini translate-y-0 opacity-100': pageScrolled,
                'translate-y-5 opacity-0':
                    !pageScrolled || (hiddenViews.includes($route.meta.name) && tablet) || portrait,
                'text-dark-blue': darkViews.includes($route.meta.name),
                'text-white': !darkViews.includes($route.meta.name),
                'z-0': (hiddenViews.includes($route.meta.name) && tablet) || portrait,
            },
        ]"
        v-on:click="toTop"
    >
        <div ref="text_container" class="ScrollTop__text-container sr-only">
            {{ ui.scroll_top }}
        </div>
        <div class="ScrollTop__icon transition duration-500 group-hover:-translate-y-3">
            <Icon
                type="arrow"
                class="-rotate-90 transition duration-500"
                svgclass="w-8 xl:w-5 h-auto fill-current"
            />
        </div>
    </div>
</template>
