<template>
    <svg class="LogoFull" viewBox="0 0 164 200">
        <path fill="#f1716f" d="M1.42,120.23a79.77,79.77,0,0,0,159.54,0Z" />
        <path fill="#bcd75a" d="M65.34,42.45a10.74,10.74,0,0,0,21.47,0Z" />
        <path fill="#bcd75a" d="M85.84,110.72H66.37V58.79H85.84Z" />
        <path
            fill="#bcd75a"
            d="M127,41c10.74,0,19.47,3.41,26.4,10.24s10.44,15.25,10.44,25.39-3.51,18.68-10.44,25.5-15.66,10.14-26.4,10.14-19.58-3.41-26.5-10.14S90.16,86.72,90.16,76.58s3.41-18.67,10.34-25.39S116.26,41,127,41Zm0,52.3a15.17,15.17,0,0,0,11.64-4.92,17.24,17.24,0,0,0,0-23.49,16.24,16.24,0,0,0-23.29,0,17.27,17.27,0,0,0,0,23.49A15.19,15.19,0,0,0,127,93.25Z"
        />
        <path
            fill="#bcd75a"
            d="M52.49,74.67A18,18,0,0,1,63,91.28c0,11.06-9.55,19.44-22.15,19.44H1.42V42.45H40.18c12.1,0,20.94,7.39,20.94,17.47a17.4,17.4,0,0,1-8.63,14.75ZM43.45,89c0-3.63-2.66-6.18-6.74-6.18H21.44V95.28H36.71C40.79,95.28,43.45,92.72,43.45,89ZM42.36,63c0-3.55-2.43-5.95-6.36-5.95H21.44V69H36c3.94,0,6.36-2.4,6.36-5.94Z"
        />
        <path
            fill="#679840"
            d="M28.91,29.27l3,3.69-6.22,4.53L21.94,33a16.61,16.61,0,0,1-4.7.61A16.68,16.68,0,0,1,5.05,28.75,16.22,16.22,0,0,1,.16,16.8,16,16,0,0,1,5.05,4.89,16.52,16.52,0,0,1,17.24,0,16.45,16.45,0,0,1,29.43,4.89,16.08,16.08,0,0,1,34.37,16.8,16.12,16.12,0,0,1,28.91,29.27ZM8.27,16.8a9.39,9.39,0,0,0,2.57,6.64,8.44,8.44,0,0,0,6.4,2.7,8.48,8.48,0,0,0,6.41-2.7,9.43,9.43,0,0,0,2.56-6.64,9.25,9.25,0,0,0-2.56-6.6,8.35,8.35,0,0,0-6.41-2.75,8.31,8.31,0,0,0-6.4,2.75A9.21,9.21,0,0,0,8.27,16.8Z"
        />
        <path
            fill="#679840"
            d="M59.86,32.93H52.18V31.41a10.28,10.28,0,0,1-6.31,2.23,9.35,9.35,0,0,1-7.12-2.85A10.74,10.74,0,0,1,36,23.06V9.92h7.69v12c0,3.18,1.61,4.84,4.22,4.84a5.78,5.78,0,0,0,4.23-2.28V9.92h7.68Z"
        />
        <path
            fill="#679840"
            d="M108.63,13a11.72,11.72,0,0,1,3.37,8.5A11.91,11.91,0,0,1,108.63,30a10.75,10.75,0,0,1-8.06,3.47,11.67,11.67,0,0,1-6.26-1.71v1.14H86.57V.44h7.74V11.2a11.67,11.67,0,0,1,6.26-1.71A10.78,10.78,0,0,1,108.63,13Zm-4.17,8.5a5.65,5.65,0,0,0-1.57-4,5,5,0,0,0-3.84-1.66,6.08,6.08,0,0,0-4.74,2.14V25.1a6,6,0,0,0,4.74,2.09A5.49,5.49,0,0,0,104.46,21.45Z"
        />
        <path
            fill="#679840"
            d="M137.1,23.68H120.73c.71,2.46,2.47,3.7,5.17,3.7a3.85,3.85,0,0,0,3.51-1.66h7.64c-1.14,4.74-5.55,7.73-11.24,7.73a12.62,12.62,0,0,1-9.11-3.37,11.45,11.45,0,0,1-3.47-8.68,11.74,11.74,0,0,1,3.37-8.64,12.26,12.26,0,0,1,8.92-3.37,11.7,11.7,0,0,1,8.4,3.23,11,11,0,0,1,3.32,8.3A20.43,20.43,0,0,1,137.1,23.68Zm-16.42-4.51H130a4.15,4.15,0,0,0-4.56-3.84A4.51,4.51,0,0,0,120.68,19.17Z"
        />
        <polygon fill="#679840" points="69.97 6.5 72.99 0.44 80.91 0.44 77.13 6.5 69.97 6.5" />
        <path
            fill="#679840"
            d="M85.05,23.68H68.69c.71,2.46,2.46,3.7,5.17,3.7a3.85,3.85,0,0,0,3.51-1.66H85c-1.14,4.74-5.55,7.73-11.25,7.73a12.63,12.63,0,0,1-9.11-3.37,11.48,11.48,0,0,1-3.46-8.68,11.74,11.74,0,0,1,3.37-8.64,12.24,12.24,0,0,1,8.92-3.37,11.7,11.7,0,0,1,8.4,3.23,11,11,0,0,1,3.32,8.3A18.57,18.57,0,0,1,85.05,23.68ZM68.64,19.17H78a4.15,4.15,0,0,0-4.56-3.84A4.51,4.51,0,0,0,68.64,19.17Z"
        />
        <path
            fill="#679840"
            d="M155.65,23.49h7.26a10.32,10.32,0,0,1-3.75,7.21,12.06,12.06,0,0,1-8.07,2.75,12.57,12.57,0,0,1-9-3.37,11.45,11.45,0,0,1-3.56-8.68,11.31,11.31,0,0,1,3.56-8.59,12.61,12.61,0,0,1,9-3.42,12.1,12.1,0,0,1,8.07,2.8,10.23,10.23,0,0,1,3.75,7.17h-7.26a4.32,4.32,0,0,0-4.51-3.47c-3.22,0-5.26,2.28-5.26,5.51s2,5.55,5.26,5.55A4.32,4.32,0,0,0,155.65,23.49Z"
        />
    </svg>
</template>
